import styled from 'styled-components';

const LocationSectionStyle = styled.div`
	--locationFilterMarginTopBottom: var(--sp12x) 0;
	--pointTextMarginLeft: var(--sp1-5x);
	--pointMarginTop: var(--sp4x);
	--activeFilterTitleMarginBottom: var(--sp8x);
	--mapHeight: var(--sp122x);

	margin-bottom: var(--sectionDistance);

	.map-wrap {
		height: var(--mapHeight) !important;

		.map-pin {
			font-size: var(--locationIconSizes);
			cursor: pointer;

			&.active {
				pointer-events: none;

				&::before {
					color: var(--red1000);
				}
			}

			&::before {
				color: var(--red1000);

				transition: 0.3s;
			}

			@media (hover: hover) {
				&:hover {
					&::before {
						color: var(--green800);
					}
				}
			}
		}

		.mapboxgl-map {
			flex: 1;
			max-width: 100%;
			min-height: 100%;
			max-height: 100%;
			height: auto !important;

			.mapboxgl-canvas-container {
				.mapboxgl-canvas {
					width: 100% !important;
					height: 100% !important;
				}
			}
		}
	}

	.location-filter {
		margin: var(--locationFilterMarginTopBottom);
	}

	.active-filter-title {
		margin-bottom: var(--activeFilterTitleMarginBottom);
	}

	.points-wrap {
		.point {
			display: flex;
			align-items: center;
			margin-top: var(--pointMarginTop);

			.point-text {
				margin-left: var(--pointTextMarginLeft);
			}
		}
	}

	.offset {
		display: none;
	}
	.titleWrapper {
		margin-bottom: var(--titleToContent);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--locationFilterMarginTopBottom: var(--sp10x) 0;
		--pointTextMarginLeft: var(--sp1x);
		--pointMarginTop: var(--sp3x);
		--activeFilterTitleMarginBottom: var(--sp6x);
		--mapHeight: var(--sp85x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--locationFilterMarginTopBottom: var(--sp8x) 0;
		--pointTextMarginLeft: var(--sp1x);
		--pointMarginTop: var(--sp3x);
		--activeFilterTitleMarginBottom: var(--sp3x);
		--mapHeight: var(--sp77x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--locationFilterMarginTopBottom: var(--sp8x) 0;
		--pointTextMarginLeft: var(--sp1x);
		--pointMarginTop: var(--sp3x);
		--activeFilterTitleMarginBottom: var(--sp3x);
		--mapHeight: var(--sp77x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--locationFilterMarginTopBottom: var(--sp6x) 0;
		--pointTextMarginLeft: var(--sp1x);
		--pointMarginTop: var(--sp3x);
		--activeFilterTitleMarginBottom: var(--sp4x);
		--mapHeight: var(--sp68x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--locationFilterMarginTopBottom: var(--sp5x) 0;
		--pointTextMarginLeft: var(--sp1x);
		--pointMarginTop: var(--sp2x);
		--activeFilterTitleMarginBottom: var(--sp3x);
		--mapHeight: var(--sp50x);

		.offset {
			display: block;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--locationFilterMarginTopBottom: var(--sp5x) 0;
		--pointTextMarginLeft: var(--sp1x);
		--pointMarginTop: var(--sp2x);
		--activeFilterTitleMarginBottom: var(--sp3x);
		--mapHeight: var(--sp50x);

		.map-container {
			.row {
				flex-direction: column-reverse;

				.active-filter-title {
					margin-top: var(--sp4x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--locationFilterMarginTopBottom: var(--sp3x) 0;
		--pointTextMarginLeft: var(--sp1x);
		--pointMarginTop: var(--sp2x);
		--activeFilterTitleMarginBottom: var(--sp3x);
		--mapHeight: var(--sp50x);

		.map-container {
			.row {
				flex-direction: column-reverse;

				.active-filter-title {
					margin-top: var(--sp3x);
				}
			}
		}
	}
`;

export default LocationSectionStyle;
