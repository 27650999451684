'use client';

//* HOC's
import withDataContext from '@/context/consumerHOC/DataConsumer';

//* Style
import HomeStyle from '@/styles/pages/HomeStyle';

//* Components
import Page from '@/components/common/Page';
import HeroSection from '@/modules/home/HeroSection';
import AboutProjectSection from '@/modules/home/AboutProjectSection';
import SpecialSection from './SpecialSection';
import GallerySection from '@/modules/home/GallerySection';
import VideoSection from './VideoSection';
import LocationSection from '@/modules/home/LocationSection';

const LandingTemplate = ({ globalData }) => {
    return (
        <Page className={'home'}>
            <HomeStyle>
                <HeroSection {...globalData.hero} />

                <AboutProjectSection {...globalData.about_project} />

                <SpecialSection {...globalData.special_offer} />

                <GallerySection {...globalData.gallery} />

                <VideoSection {...globalData.video_gallery} />

                <LocationSection {...globalData.location} />
            </HomeStyle>
        </Page>
    );
};

export default withDataContext(LandingTemplate, ['globalData']);
