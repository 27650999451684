import { useRef, useEffect, useMemo, useState } from 'react';
import gsap from 'gsap';

//* Style
import HeroLandingSectionStyle from './style';

//* HOC's
import { withUIContext } from '@/context';

//* Components
import Image from '@/components/common/Image';
import Text from '@/components/common/Text';
import ScrollActive from '@/components/common/ScrollActive';

const HeroSection = ({ title, banner_file_main, banner_file_mobile, winWidth }) => {
	//! Refs
	const imageRef = useRef();
	const titleHero = useRef();
	const wrapperHeroLayer = useRef();

	//! State
	const [isImage, setIsImage] = useState(false);

	useEffect(() => {
		gsap.to(imageRef.current.children[0], { clipPath: 'inset(0% 0% 0% 0%)', ease: 'power3.easeOut', duration: 1.2, delay: 0.5 });
		gsap.to(wrapperHeroLayer.current, { clipPath: 'inset(0% 0% 0% 0%)', ease: 'power3.easeOut', duration: 1.2, delay: 0.5 });

		setTimeout(() => {
			setIsImage(true);
		}, 1000);
	}, []);

	//! Detect Screen Size
	const screenSize = useMemo(() => {
		return winWidth >= 768 ? banner_file_main : banner_file_mobile;
	}, [banner_file_main, banner_file_mobile, winWidth]);

	return (
		<HeroLandingSectionStyle>
			<div className='wrapper-hero'>
				<div
					className='wrapperHeroLayer'
					ref={wrapperHeroLayer}></div>
				<Image
					ref={imageRef}
					className={'hero'}
					src={screenSize?.src}
					alt={screenSize?.alt}
				/>

				<div className='opacity-0 row'>
					{isImage && (
						<ScrollActive
							scrollNoneEveryLine
							allRefs={titleHero}
							duration={0.4}
							delay={0.2}
							scrollTrigger={false}
							reverse>
							<Text
								ref={titleHero}
								className={'h1 font-montserrat-medium white hero-title col-6 col-dl-8 col-dm-10 col-t-9 col-ts-12 col-dxs-12 col-m-12'}>
								{title}
							</Text>
						</ScrollActive>
					)}
				</div>
			</div>
		</HeroLandingSectionStyle>
	);
};

export default withUIContext(HeroSection, ['winWidth']);
