import { useEffect, useRef } from 'react';
import gsap from 'gsap';

//* Helpers
import { useActiveSection } from '@/helpers';

//* Components
import Image from '@/components/common/Image';

//* Style
import SpecialImageStyle from './style';

const SpecialImage = ({ item, className }) => {
	//! Refs
	const imageRef = useRef([]);

	//! Active section
	const activeSection = useActiveSection(imageRef, 80);

	useEffect(() => {
		if (activeSection) {
			gsap.to(imageRef.current.children[0], {
				scale: 1,
				clipPath: 'inset(0% 0% 0% 0%)',
				ease: 'power3.easeOut',
				duration: 1,
			});
		}
	}, [activeSection]);

	return (
		<SpecialImageStyle>
			<div className={className}>
				<Image
					ref={imageRef}
					src={item?.src}
					alt={item?.alt}
					className={activeSection ? 'active' : ''}
				/>
			</div>
		</SpecialImageStyle>
	);
};

export default SpecialImage;
