import styled from 'styled-components';

const SpecialSectionStyled = styled.div`
	--callToActionUpperPadding: var(--sp17x);
	--callToActionUpperPaddingLR: var(--sp8x);
	--callToActionBorderRadius: var(--sp4x);
	--circleSizes: var(--sp41x);
	--staticCursorPositionTop: 0;
	--staticCursorPositionRight: 0;
	--imagePTB: var(--sp12x);
	--containerPadLR: var(--sp4x);
	--descriptionDistance: var(--sp7x);
	--ellipsePositionL: 57.5%;
	--ellipsePositionT: 68%;
	--styleCursor: none;

	padding: 0 var(--containerPadLR);
	margin-bottom: var(--sectionDistance);

	.wrapperSpecial {
		border-radius: var(--callToActionBorderRadius);
	}

	.call-to-action {
		padding: var(--callToActionUpperPadding) var(--callToActionUpperPaddingLR);
		position: relative;
		display: flex;
		justify-content: center;
		flex-direction: column;
		cursor: var(--styleCursor);
		/* text-align: center;
		cursor: none; */

		.titleSection {
			display: flex;
			justify-content: center;
			.upper-text-wrap {
				p {
					div {
						text-align: center;
					}
				}
			}
		}

		//! Cursor styles
		.call-to-action-drag-container {
			position: absolute;
			z-index: 1000;
			pointer-events: none;
			left: var(--ellipsePositionL);
			top: var(--ellipsePositionT);
			opacity: 0;

			.call-to-action-drag {
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				width: var(--circleSizes);
				height: var(--circleSizes);
				border-radius: 50%;
				color: var(--white);
				background-color: var(--red1000);
				transform: translate(-50%, -50%) rotate(-30deg);
				text-align: center;
			}
		}
	}

	.imageWrapper {
		width: 100%;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		padding-top: var(--imagePTB);
	}

	.wrapper-description {
		justify-content: center;
		margin-top: var(--descriptionDistance);
	}

	.static-cursor {
		display: none;
		position: absolute;
		top: var(--staticCursorPositionTop);
		right: var(--staticCursorPositionRight);

		.call-to-action-drag {
			display: flex;
			align-items: center;
			justify-content: center;
			width: var(--circleSizes);
			height: var(--circleSizes);
			border-radius: 50%;
			color: var(--white);
			background-color: var(--red1000);
			transform: rotate(-30deg);
			text-align: center;
			position: absolute;
			z-index: 100;
			left: 30%;
			bottom: -7%;
			cursor: pointer;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--callToActionBorderRadius: var(--sp4x);
		--circleSizes: var(--sp31x);
		--containerPadLR: var(--sp3x);
		--imagePTB: var(--sp12x);
		--callToActionUpperPadding: var(--sp7x);
		--callToActionUpperPaddingLR: var(--sp7x);
		--descriptionDistance: var(--sp5x);

		--ellipsePositionL: 64%;
		--ellipsePositionT: 81%;

		.call-to-action {
			.titleSection {
				margin-top: var(--sp8x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--callToActionBorderRadius: var(--sp4x);
		--circleSizes: var(--sp27x);
		--containerPadLR: var(--sp2x);
		--imagePTB: var(--sp8x);
		--callToActionUpperPadding: var(--sp11x);
		--callToActionUpperPaddingLR: var(--sp6x);
		--descriptionDistance: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--callToActionBorderRadius: var(--sp4x);
		--circleSizes: var(--sp27x);
		--containerPadLR: var(--sp2x);
		--imagePTB: var(--sp8x);
		--callToActionUpperPadding: var(--sp11x);
		--callToActionUpperPaddingLR: var(--sp5x);
		--descriptionDistance: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--callToActionBorderRadius: var(--sp4x);
		--circleSizes: var(--sp24x);
		--containerPadLR: var(--sp2x);
		--imagePTB: var(--sp7x);
		--callToActionUpperPadding: var(--sp9x);
		--callToActionUpperPaddingLR: var(--sp8x);
		--descriptionDistance: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--callToActionBorderRadius: var(--sp4x);
		--circleSizes: var(--sp20x);
		--imagePTB: var(--sp5x);
		--callToActionUpperPadding: var(--sp7x);
		--callToActionUpperPaddingLR: var(--sp4x);

		--staticCursorPositionTop: var(--sp3x);
		--staticCursorPositionRight: var(--sp3x);

		--containerPadLR: var(--sp2x);
		--descriptionDistance: var(--sp2x);
		--styleCursor: default;

		.call-to-action {
			display: flex;
			flex-direction: column;
		}

		.static-cursor {
			display: block;
			position: absolute;
			bottom: 26%;
			left: 18%;
			text-align: center;
		}

		.call-to-action-drag-container {
			display: none;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--callToActionBorderRadius: var(--sp4x);
		--circleSizes: var(--sp20x);

		--staticCursorPositionTop: var(--sp3x);
		--staticCursorPositionRight: var(--sp3x);
		--containerPadLR: var(--sp2x);
		--imagePTB: var(--sp5x);
		--callToActionUpperPadding: var(--sp8x);
		--callToActionUpperPaddingLR: var(--sp2x);
		--descriptionDistance: var(--sp2x);
		--styleCursor: default;

		.call-to-action {
			display: flex;
			flex-direction: column;
		}

		.static-cursor {
			display: block;
			position: absolute;
			bottom: 20%;
			left: 18%;
			text-align: center;
		}

		.call-to-action-drag-container {
			display: none;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--callToActionBorderRadius: var(--sp2x);
		--circleSizes: var(--sp20x);

		--staticCursorPositionTop: var(--sp3x);
		--staticCursorPositionRight: var(--sp3x);

		--containerPadLR: 14px;
		--imagePTB: var(--sp4x);
		--callToActionUpperPadding: var(--sp8x);
		--callToActionUpperPaddingLR: 14px;
		--descriptionDistance: var(--sp3x);
		--styleCursor: default;

		.call-to-action {
			display: flex;
			flex-direction: column;
			padding: var(--callToActionUpperPadding) var(--callToActionUpperPaddingLR) 152px;
		}

		.static-cursor {
			width: 100%;

			display: block;
			position: absolute;
			text-align: center;
			bottom: 11%;
			left: 0;
			/* height: 100%; */
		}

		.footer-drag-container {
			display: none;
		}
	}
`;

export default SpecialSectionStyled;
