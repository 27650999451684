import { useMemo, useRef } from 'react';

//* Style
import AboutProjectSectionLandingStyle from './style';

//* Components
import Text from '@/components/common/Text';
import AboutProjectItem from './AboutProjectItem';
import Container from '@/components/common/Container';
import ScrollActive from '@/components/common/ScrollActive';
import Editor from '@/components/common/Editor';

const AboutProjectSection = ({ title, description, items }) => {
	//! Refs
	const titleRef = useRef();
	const sectionRef = useRef();
	const descriptionRef = useRef();

	//! About items
	const storeAboutItems = useMemo(() => {
		return items?.length > 0
			? items.map((item, index) => {
					return (
						<div
							key={index}
							className='col-6 col-m-12 about-item-col'>
							<AboutProjectItem
								className={`${(index + 1) % 2 === 0 ? 'even' : 'odd'}`}
								{...item}
							/>
						</div>
					);
			  })
			: null;
	}, [items]);

	return (
		<AboutProjectSectionLandingStyle ref={sectionRef}>
			<Container
				row
				className={'about-content-wrap'}>
				<div className='col-4 col-t-3 col-m-12 offset' />

				<div className='col-8 col-t-9 col-m-12'>
					<div className='section-title'>
						<ScrollActive
							animEveryLine
							allRefs={titleRef}
							duration={0.5}
							delay={0.25}
							delayAnim={0.1}
							reverse>
							<Text
								ref={titleRef}
								className={'h2 font-montserrat-medium main-blue title opacity-0'}>
								{title}
							</Text>
						</ScrollActive>
					</div>

					<div className='section-description opacity-0'>
						<ScrollActive
							animLine
							delay={0.15}
							delayAnim={0}
							duration={0.8}
							allRefs={descriptionRef}>
							<Text
								ref={descriptionRef}
								className={'h4 font-montserrat main-blue description'}>
								{description}
							</Text>
						</ScrollActive>
					</div>
				</div>
			</Container>

			<Container
				row
				className={'about-items-container'}>
				{storeAboutItems}
			</Container>
		</AboutProjectSectionLandingStyle>
	);
};

export default AboutProjectSection;
