import { useMemo, useRef, useEffect } from 'react';
import Map, { Marker, NavigationControl } from 'react-map-gl';
import { ScrollTrigger } from 'gsap/all';
import gsap from 'gsap';

//* Styles
import LocationSectionStyle from './style';
import 'mapbox-gl/dist/mapbox-gl.css';

//* Components
import Text from '@/components/common/Text';
import Icon from '@/components/common/Icon';
import Container from '@/components/common/Container';
import ScrollActive from '@/components/common/ScrollActive';

const LocationSection = ({ title, subtitle, latitude, longitude, points }) => {
	//! Refs
	const mapWrapRef = useRef();
	const pointsWrapRef = useRef();
	const titleRef = useRef();
	const refsIcon = useRef([]);
	const parentRefLocation = useRef([]);
	const firstTitleRefs = useRef();

	const storePoints = useMemo(() => {
		return points.map((point, index) => {
			return (
				<div
					key={index}
					className='overflow-hidden'>
					<div
						className='point overflow-hidden translate-y-100'
						ref={(ref) => ref && (refsIcon.current[index] = ref)}>
						<Icon
							className={'h6'}
							name={'check'}
						/>
						<Text className={'h6 point-text font-montserrat-medium'}>{point.title}</Text>
					</div>
				</div>
			);
		});
	}, [points]);

	useEffect(() => {
		refsIcon.current.forEach((item) => (item.style = ''));
		firstTitleRefs.current.style = '';

		ScrollTrigger.create({
			trigger: refsIcon.current,
			start: 'top 80%',
			end: 'top top',
			// markers: true,
			once: true,
			onEnter: () => {
				gsap.fromTo(
					firstTitleRefs.current,
					{
						y: '100%',
					},
					{
						duration: 0.5,
						ease: 'power2.out',
						y: 0,
					}
				);
				refsIcon.current.forEach((item, i) => {
					gsap.fromTo(
						item,
						{
							y: '100%',
						},
						{
							y: 0,
							opacity: 1,
							duration: 0.5,
							ease: 'power2.out',

							delay: (i + 1) * 0.1,
						}
					);
				});
			},
		});
	}, []);

	return (
		<LocationSectionStyle>
			<Container className={'titleWrapper'}>
				<ScrollActive
					animEveryLine
					allRefs={titleRef}
					duration={0.5}
					delay={0.1}
					delayAnim={0.1}
					reverse>
					<Text
						ref={titleRef}
						className={'h1 font-montserrat-medium opacity-0'}>
						{title}
					</Text>
				</ScrollActive>
			</Container>

			<Container
				row
				className={'map-container'}>
				<div className={'col-3 col-t-2 col-ts-12'}>
					<div
						className='points-wrap'
						ref={pointsWrapRef}>
						<div className='overflow-hidden active-filter-title'>
							<Text
								ref={firstTitleRefs}
								className='h3 font-montserrat-medium translate-y-100'>
								{subtitle}
							</Text>
						</div>

						<div ref={parentRefLocation}>{storePoints}</div>
					</div>
				</div>

				<div className='col-t-1 offset' />

				<div className={'col-9 col-t-9 col-ts-12'}>
					<div
						className='map-wrap'
						ref={mapWrapRef}>
						<Map
							mapStyle={process.env.MAPBOX_STYLE}
							mapboxAccessToken={process.env.MAPBOX_TOKEN}
							initialViewState={{
								longitude: longitude,
								latitude: latitude,
								zoom: 15,
							}}>
							<Marker
								longitude={longitude}
								latitude={latitude}>
								<Icon
									className={`map-pin active`}
									name={'location'}
								/>
							</Marker>

							<NavigationControl />
						</Map>
					</div>
				</div>
			</Container>
		</LocationSectionStyle>
	);
};

export default LocationSection;
