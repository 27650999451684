import styled from 'styled-components';

const AboutProjectItemStyle = styled.div`
	--aboutItemTitleMargin: var(--sp7x) 0;
	--proportionAbout: 91.8%;

	&.odd {
		.about-item {
			.imageCont {
				margin-top: 29.1%;
			}
		}
	}

	.about-item {
		.item-title-wrap {
			overflow: hidden;

			.item-title {
				margin: var(--aboutItemTitleMargin);
			}
		}

		.item-text-wrap {
			overflow: hidden;
		}

		.imageCont {
			padding-top: var(--proportionAbout);
			overflow: hidden;

			img {
				transform: scale(1.2);
				clip-path: inset(0% 0% 100% 0%);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--aboutItemTitleMargin: var(--sp5x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--aboutItemTitleMargin: var(--sp4x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--aboutItemTitleMargin: var(--sp4x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--aboutItemTitleMargin: var(--sp4x) 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--aboutItemTitleMargin: var(--sp3x) 0;
		--proportionAbout: 58%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--aboutItemTitleMargin: var(--sp4x) 0;
		--proportionAbout: 58%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--aboutItemTitleMargin: var(--sp3x) 0;
		--proportionAbout: 58%;

		&.odd {
			.about-item {
				.imageCont {
					margin-top: 0;
				}
			}
		}
	}
`;

export default AboutProjectItemStyle;
