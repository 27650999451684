import { useMemo, useState, useRef, useEffect } from 'react';
import { gsap } from 'gsap';

//* Helpers
import { useActiveSection } from '@/helpers';

//* Style
import GallerySectionStyle from './style';

//* Components
import Text from '@/components/common/Text';
import Image from '@/components/common/Image';
import Button from '@/components/common/Button';
import Container from '@/components/common/Container';
import ScrollActive from '@/components/common/ScrollActive';

const GallerySection = ({ title, items }) => {
	//! Refs
	const slidesRef = useRef();
	const titleRef = useRef();
	const sectionRef = useRef();

	//! State
	const [activeIndex, setActiveIndex] = useState(0);

	//! Active section
	const activeSection = useActiveSection(sectionRef);

	//! All images
	const storeAllImages = useMemo(() => {
		return items?.length > 0
			? items.map((slide, index) => {
					const prevClass = activeIndex > index ? 'prev-slide' : '';
					const nextClass = activeIndex < index ? 'next-slide' : '';

					return (
						<Image
							key={index}
							src={slide?.src}
							alt={slide?.alt}
							className={`slide ${activeIndex === index ? 'active' : ''} ${prevClass} ${nextClass}`}
						/>
					);
			  })
			: null;
	}, [items, activeIndex]);

	useEffect(() => {
		if (activeSection) {
			gsap.to(slidesRef.current, { scale: 1, duration: 1, ease: 'power3.easeOut' });
		}
	}, [activeSection]);

	return items?.length > 0 ? (
		<GallerySectionStyle ref={sectionRef}>
			<Container>
				<div className='gallery-title-wrap'>
					<ScrollActive
						animEveryLine
						allRefs={titleRef}
						duration={0.5}
						delay={0.1}
						delayAnim={0.1}
						reverse>
						<Text
							ref={titleRef}
							className={'h1 font-montserrat-medium gallery-title opacity-0'}>
							{title}
						</Text>
					</ScrollActive>
				</div>

				<div className='slides-container'>
					<div
						className='slides-wrap'
						ref={slidesRef}>
						{storeAllImages}
					</div>

					<Button
						isFullRadius
						btnType={'frame'}
						className={'prev-button'}
						isDisabled={activeIndex === 0}
						onClick={() => setActiveIndex(activeIndex - 1)}
					/>

					<Button
						isFullRadius
						btnType={'frame'}
						className={'next-button'}
						isDisabled={activeIndex === items.length - 1}
						onClick={() => setActiveIndex(activeIndex + 1)}
					/>
				</div>
			</Container>
		</GallerySectionStyle>
	) : null;
};

export default GallerySection;
