//* Style
import { useRef, useCallback, useMemo } from 'react';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/all';

//* HOC's
import { withUIContext } from '@/context';

//* Style
import CallToActionStyle from './style';

//* Components
import SpecialImage from './SpecialImage';
import Text from '@/components/common/Text';
import Editor from '@/components/common/Editor';
import Container from '@/components/common/Container';
import ScrollActive from '@/components/common/ScrollActive';
import PopupRequestCallOrReservation from '@/components/common/PopupRequestCallOrReservation';

const SpecialSection = ({ winWidth, title, description, photo_01, photo_02, openPopup }) => {
	//! Plugins
	gsap.registerPlugin(SplitText);

	//! Screen check
	const isDesktop = useMemo(() => {
		return winWidth >= 1280;
	}, [winWidth]);

	//! Refs
	const cursorRef = useRef();
	const contRef = useRef();
	const sectionRef = useRef();
	const typingText = useRef();
	const editorRef = useRef();

	//! Handle mouse move
	const handleMouseMove = useCallback(
		(e) => {
			if (isDesktop) {
				const cursorContainer = contRef.current.getBoundingClientRect();

				const x = e.clientX - cursorContainer.left;
				const y = e.clientY - cursorContainer.top;

				gsap.to(cursorRef.current, { duration: 0.3, opacity: 1, left: x, top: y, scale: 1 });
			}
		},
		[isDesktop]
	);

	//! Handle mouse leave
	const handleMouseLeave = useCallback(
		(e) => {
			if (isDesktop) {
				gsap.to(cursorRef.current, { duration: 0.3, scale: 0, opacity: 0 });
			}
		},
		[isDesktop]
	);

	function clickPopupButton() {
		openPopup(<PopupRequestCallOrReservation />);
	}

	return (
		<CallToActionStyle ref={sectionRef}>
			<Container full>
				<div
					onClick={isDesktop ? clickPopupButton : null}
					ref={contRef}
					onMouseMove={handleMouseMove}
					onMouseLeave={handleMouseLeave}
					className={'call-to-action dark-green-bg wrapperSpecial'}>
					{isDesktop ? (
						<div
							ref={cursorRef}
							className={`call-to-action-drag-container`}>
							<Text
								tag={`span`}
								className={`h5 font-montserrat-medium call-to-action-drag`}
								text={`requestACall`}
							/>
						</div>
					) : null}

					<div className='titleSection row'>
						<div className='upper-text-wrap opacity-0 col-10 col-t-12'>
							<ScrollActive
								animEveryLine
								allRefs={typingText}
								duration={0.5}
								delay={0.25}
								// delayAnim={0.1}
								reverse>
								<Text
									ref={typingText}
									className={'h1 white upper-text font-montserrat-medium'}>
									{title}
								</Text>
							</ScrollActive>
						</div>
					</div>

					<div className='wrapper-description row'>
						<Editor
							ref={editorRef}
							className='col-8 col-t-9 col-m-12 text-center'
							data={description}
						/>
					</div>

					<div className='imageWrapper'>
						{[photo_01, photo_02].map((item, key) => {
							return key <= 1 ? (
								<SpecialImage
									key={key}
									item={item}
									className={`everyImage ${key % 2 ? 'rightImage' : 'leftImage'}`}
								/>
							) : null;
						})}
					</div>

					<div className='static-cursor'>
						<Text
							onClick={clickPopupButton}
							tag={`span`}
							className={`h5 font-montserrat-medium call-to-action-drag`}
							text={`requestACall`}
						/>
					</div>
				</div>
			</Container>
		</CallToActionStyle>
	);
};

export default withUIContext(SpecialSection, ['winWidth', 'openPopup']);
