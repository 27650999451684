import styled from 'styled-components';

const HeroLandingSectionStyle = styled.div`
	--heroSectionMargin: var(--sp4x) var(--sp4x) var(--sp34x) var(--sp4x);
	--heroSectionBorderRadius: var(--sp4x);
	--paddingTitleLR: var(--sp8x);
	--paddingTitleTB: var(--sp11x);
	--imageSize: 49%;
	--titleWidth: 50%;

	margin: var(--heroSectionMargin);
	border-radius: var(--heroSectionBorderRadius);
	overflow: hidden;
	.wrapper-hero {
		position: relative;
		.wrapperHeroLayer {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			opacity: 0.15;
			background-color: var(--blackLayer);
			z-index: 1000;
			clip-path: inset(0% 0% 100% 0%);
		}

		.hero {
			padding-top: var(--imageSize);
			opacity: 1;

			img {
				opacity: 1;
				clip-path: inset(0% 0% 100% 0%);
			}
		}

		.hero-title {
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 10001;
			padding: var(--paddingTitleTB) var(--paddingTitleLR);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--heroSectionMargin: var(--sp3x) var(--sp3x) var(--sp28x) var(--sp3x);
		--heroSectionBorderRadius: var(--sp4x);
		--paddingTitleLR: var(--sp7x);
		--paddingTitleTB: var(--sp10x);
		--imageSize: 49.4%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--heroSectionMargin: var(--sp2x) var(--sp2x) var(--sp22x) var(--sp2x);
		--heroSectionBorderRadius: var(--sp4x);
		--paddingTitleLR: var(--sp6x);
		--paddingTitleTB: var(--sp7x);
		--imageSize: 56.3%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--heroSectionMargin: var(--sp2x) var(--sp2x) var(--sp22x) var(--sp2x);
		--heroSectionBorderRadius: var(--sp4x);
		--paddingTitleLR: var(--sp5x);
		--paddingTitleTB: var(--sp4x);
		--imageSize: 51.5%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--heroSectionMargin: var(--sp2x) var(--sp2x) var(--sp15x) var(--sp2x);
		--heroSectionBorderRadius: var(--sp4x);
		--paddingTitleLR: var(--sp6x);
		--paddingTitleTB: var(--sp7x);
		--imageSize: 56.5%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--heroSectionMargin: var(--sp2x) var(--sp2x) var(--sp12x) var(--sp2x);
		--heroSectionBorderRadius: var(--sp4x);
		--paddingTitleLR: var(--sp4x);
		--paddingTitleTB: var(--sp5x);
		--imageSize: 56.5%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--heroSectionMargin: var(--sp2x) var(--sp2x) var(--sp12x) var(--sp2x);
		--heroSectionBorderRadius: var(--sp3x);
		--paddingTitleLR: var(--sp2x);
		--paddingTitleTB: var(--sp3x);
		--imageSize: 56%;
		--titleWidth: 70%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--heroSectionMargin: var(--sp1-5x) var(--sp1-5x) var(--sp9x) var(--sp1-5x);
		--heroSectionBorderRadius: var(--sp2x);
		--paddingTitleLR: var(--sp1-5x);
		--paddingTitleTB: var(--sp4x);
		--imageSize: 100%;
		--titleWidth: 80%;
	}
`;

export default HeroLandingSectionStyle;
