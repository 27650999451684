'use client';

import gsap from 'gsap';
import { ScrollTrigger, SplitText } from 'gsap/all';
import { useGSAP } from '@gsap/react';

//* HOC's
import { withLanguageContext } from '@/context';

const ScrollActive = ({ selectedLang, allRefs, parentRefs, animLine, scrollNoneEveryLine, animEveryLine, animOneLine, reverse, duration, delay, delayAnim, children }) => {
	//! Plugins
	gsap.registerPlugin(useGSAP);
	gsap.registerPlugin(ScrollTrigger);

	useGSAP(() => {
		//! Function Line Height to Percent
		function lineNumber(item) {
			let lineHeight = parseFloat(window.getComputedStyle(item).lineHeight);
			return (item.offsetHeight / lineHeight) * 100;
		}

		//!Animation none Scroll
		if (scrollNoneEveryLine) {
			const mySplitText = new SplitText(allRefs.current, { type: 'lines' });
			const chars = mySplitText.lines;

			chars.forEach((item, i) => {
				item.innerHTML = `<div>${item.innerText}</div>`;
				item.style.overflow = 'hidden';
				item.children[0].style.transform = `translateY( ${reverse ? '100%' : '-100%'}`;

				gsap.set(allRefs.current, {
					delay: delayAnim,
				}).then(() => {
					allRefs.current.parentElement.style.opacity = '1';
					allRefs.current.style.opacity = '1';
					gsap.to(item.children[0], {
						y: '0',
						opacity: 1,
						ease: 'power2.out',
						duration: duration || 0.1,
						delay: delay * i || 0.1,
					});
				});
			});
		}

		//! Animation Line to up
		if (animEveryLine) {
			const mySplitText = new SplitText(allRefs.current, { type: 'lines' });
			const chars = mySplitText.lines;

			ScrollTrigger.create({
				trigger: allRefs.current,
				start: 'center 80%',
				end: 'center top',
				// markers: true,
				once: true,

				onEnter: () => {
					chars.forEach((item, i) => {
						item.innerHTML = `<div>${item.innerText}</div>`;
						item.style.overflow = 'hidden';
						item.children[0].style.transform = `translateY( ${reverse ? '100%' : '-100%'}`;

						gsap.set(allRefs.current, {
							delay: delayAnim,
						}).then(() => {
							allRefs.current.parentElement.style.opacity = '1';
							allRefs.current.style.opacity = '1';

							gsap.to(item.children[0], {
								y: '0',
								opacity: 1,
								ease: 'power2.out',
								duration: duration || 0.1,
								delay: delay * i || 0.1,
							});
						});
					});
				},
			});
		}

		//! Animation One Line
		if (animOneLine) {
			ScrollTrigger.create({
				trigger: parentRefs.current,
				start: 'top 80%',
				end: 'top top',
				// markers: true,
				once: true,

				onEnter: () => {
					if (Array.isArray(allRefs.current)) {
						allRefs.current.forEach((item, i) => {
							item.style.overflow = 'hidden';
							gsap.fromTo(
								item.children,
								{
									y: `${reverse ? '-100%' : '100%'}`,
								},
								{
									y: '0',
									opacity: 1,
									ease: 'power2.out',
									duration: duration,
									delay: delay * (i + 1),
								}
							);
						});
					}
				},
			});
		}

		//! Animation Line Translate
		if (animLine) {
			const textContainer = allRefs.current;
			const text = textContainer.textContent;
			const lines = text.split('\n');

			textContainer.innerHTML = '';

			lines.forEach((line) => {
				const lineDiv = document.createElement('div');
				lineDiv.textContent = line;
				lineDiv.classList.add('line');
				textContainer.appendChild(lineDiv);
			});

			const allChars = [];

			for (let index = 0; index < textContainer.children.length; index++) {
				const mySplitText = new SplitText(textContainer.children[index], { type: 'lines' });
				const chars = mySplitText.lines;
				allChars.push(...chars);
			}

			allChars.forEach((line) => {
				textContainer.appendChild(line);
			});

			ScrollTrigger.create({
				trigger: allRefs.current,
				start: 'top 80%',
				end: 'top top',
				once: true,
				// markers: true,

				onEnter: () => {
					allRefs.current.style.transform = 'translateY(100%)';

					allChars.forEach((item, i) => {
						item.innerHTML = `<div>${item.innerText}</div>`;
						item.style.overflow = 'hidden';

						gsap.set(allRefs.current.parentElement, {
							opacity: 0,
							delay: delayAnim,
						}).then(() => {
							allRefs.current.parentElement.style.opacity = '1';

							gsap.to(item, {
								duration: duration * 2,
								ease: 'power2.out',
								yPercent: `-${lineNumber(allRefs.current)}`,
								delay: delay * (i + 1),
							});

							gsap.fromTo(
								item.children[0],
								{
									opacity: 1,
									y: '-100%',
								},
								{
									y: '0',
									opacity: 1,
									ease: 'power2.out',
									duration: duration * 2 || 0.1,
									delay: delay * (i + 1) * 1.4 || 0.1,
								}
							);
						});
					});
				},
			});
		}
	}, [selectedLang]);

	return children;
};

export default withLanguageContext(ScrollActive, ['selectedLang']);
