import { forwardRef } from 'react';

//* Style
import EditorStyle from './style';

const Editor = forwardRef(({ className, data }, ref) => {
	return (
		<EditorStyle
			ref={ref}
			dangerouslySetInnerHTML={{ __html: data }}
			className={`editor-wrapper ${className || ''}`}
		/>
	);
});

export default Editor;
