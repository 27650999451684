import styled from 'styled-components';

const EditorStyle = styled.div`
	* {
		font-family: var(--montserrat);
		color: var(--white);
	}

	h5 {
		font-weight: 500;
		font-size: var(--h4);
		line-height: var(--lineHeight2);
	}

	h6 {
		line-height: var(--lineHeight3);

		font-size: var(--h5);
		font-weight: 500;
	}

	p {
		font-weight: 400;
		font-size: var(--h4);
		line-height: var(--lineHeight1);
	}

	a {
		font-weight: 400;
		text-decoration: underline;

		@media (hover: hover) {
			&:hover {
				text-decoration: none;
			}
		}
	}
	i {
		text-decoration: none;
		text-decoration: underline;
		font-style: normal;
	}

	strong {
		font-weight: 600;
	}

	ol {
		list-style-position: inside;

		li {
			font-weight: 400;
			font-size: var(--h4);
		}
	}

	ul {
		list-style: disc;
		list-style-position: inside;

		li {
			font-weight: 400;
			font-size: var(--h4);
		}
	}

	blockquote {
		border-left: 3px solid var(--white);
		padding-left: 2vw;
		font-weight: 400;
	}
`;

export default EditorStyle;
