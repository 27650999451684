import styled from 'styled-components';

const SpecialImageStyle = styled.div`
	--padImageLR: var(--sp1-5x);
	--imageSize: 58.3%;
	--translateRightImage: -50%;

	width: 50%;

	.everyImage {
		width: 100%;

		.imageCont {
			position: relative;
			padding-top: var(--imageSize);
			overflow: hidden;

			img {
				transform: scale(1.2);
				clip-path: inset(0% 0% 100% 0%);
			}
		}

		&.rightImage {
			padding-left: var(--padImageLR);
		}

		&.leftImage {
			margin-top: 29.1%;
			padding-right: var(--padImageLR);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--translateRightImage: -34%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--padImageLR: var(--sp1x);
		--translateRightImage: -37%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		width: 100%;

		.everyImage {
			&.rightImage {
				transform: translate(0);
				padding-left: 0;
			}
			&.leftImage {
				margin-top: 0;
				padding-bottom: var(--padImageLR);
				padding-right: 0;
			}
		}
	}
`;
export default SpecialImageStyle;
