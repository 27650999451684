import { useRef, useEffect } from 'react';
import { gsap } from 'gsap';

//* Helpers
import { useActiveSection } from '@/helpers';

//* Style
import AboutProjectItemStyle from './style';

//* Components
import Image from '@/components/common/Image';
import Text from '@/components/common/Text';
import ScrollActive from '@/components/common/ScrollActive';

const AboutProjectItem = ({ image, title, description, className }) => {
	//! Refs
	const itemRef = useRef();
	const titleRef = useRef();
	const textRef = useRef();
	const imageRef = useRef();

	//! Active section
	const activeSection = useActiveSection(itemRef, 80);

	useEffect(() => {
		if (activeSection) {
			gsap.to(imageRef.current.children[0], { scale: 1, clipPath: 'inset(0% 0% 0% 0%)', ease: 'power3.easeOut', duration: 1 });
		}
	}, [activeSection]);

	return (
		<AboutProjectItemStyle
			ref={itemRef}
			className={className || ''}>
			<div className={`about-item`}>
				<Image
					className={activeSection ? 'active' : ''}
					ref={imageRef}
					src={image?.src}
					alt={image?.alt}
				/>
				<div className='item-title-wrap'>
					<ScrollActive
						animEveryLine
						allRefs={titleRef}
						duration={0.5}
						delay={0.25}
						delayAnim={0.1}
						reverse>
						<Text
							className={'h2 font-montserrat item-title opacity-0'}
							ref={titleRef}>
							{title}
						</Text>
					</ScrollActive>
				</div>
				<div className='item-text-wrap opacity-0'>
					<ScrollActive
						animLine
						allRefs={textRef}
						delay={0.15}
						duration={0.8}
						delayAnim={0}>
						<Text
							className={'h5 font-montserrat item-text'}
							ref={textRef}>
							{description}
						</Text>
					</ScrollActive>
				</div>
			</div>
		</AboutProjectItemStyle>
	);
};

export default AboutProjectItem;
